/** @jsx jsx */
import { FC, useContext } from "react"
import { Link } from "gatsby"
import { jsx, Box, Container } from "theme-ui"
import { useMedia } from "react-use"

import { LogoEngineering } from "icons/LogoEngineering"
import { ScrollContext } from "components/Layout"

import useScrollDirection, { Scroll } from "hooks/use-scroll-direction"

import { NavbarLink } from "./Link"
import { animated, useSpring, config } from "react-spring"
import { ColorGreen300 } from "../../gatsby-plugin-theme-ui"

export interface IPropsNavbar {
  showLogo?: boolean
  navOnLoad?: "transparent" | "opaque"
}

const AnimatedBox = animated(Box)

export const Navbar: FC<IPropsNavbar> = ({
  showLogo = true,
  navOnLoad = "opaque",
}) => {
  const { scrolled } = useContext(ScrollContext)
  const scrollDirection = useScrollDirection()

  const hide = scrolled && scrollDirection === Scroll.DOWN
  const isMedium = useMedia("(min-width: 750px)")

  const { contentY } = useSpring({
    from: {
      contentY: 0,
    },
    contentY: hide ? -100 : 0,
    config: config.stiff,
  })

  return (
    <AnimatedBox
      as="nav"
      style={{
        transform: contentY.to((value: number) => `translate3d(0,${value}%,0)`),
      }}
      sx={{
        position: "fixed",
        zIndex: "navbar",
        pointerEvents: "none",
        left: 0,
        right: 0,
        ...(navOnLoad === "opaque"
          ? {
              bg: "backgroundPrimary",
            }
          : {
              background: `linear-gradient(${ColorGreen300} 0%, ${ColorGreen300} 10%, white 10%, white 100%)`,
            }),
      }}
    >
      <Container
        sx={{
          backgroundColor: "backgroundPrimary",
          pointerEvents: "none",
          display: "flex",
          alignItems: "center",
          height: [48, 56, null, 64, 80, 88],
        }}
      >
        {showLogo && (
          <Link
            to="/"
            sx={{
              transformOrigin: "left center",
              display: "inline-grid",
              maxWidth: [120, 172, 144, 184, 240],
              variant: "layout.focusVisible",
              pointerEvents: "all",
              position: "relative",
              top: ["-2px", "-3px", "-2px", "-4px"],
            }}
          >
            <LogoEngineering
              sx={{
                display: "block",
                width: "100%",
                height: "auto",
              }}
            />
          </Link>
        )}
        <Box
          as="ul"
          sx={{
            ml: "auto",
            display: "grid",
            gridAutoFlow: "column",
            gap: [4, 5, 7],
            alignItems: "baseline",
          }}
        >
          <NavbarLink to="/about">About</NavbarLink>
          <NavbarLink to="/careers">Jobs</NavbarLink>
          <NavbarLink to="/career-ladder">Career Ladder</NavbarLink>
        </Box>
      </Container>
    </AnimatedBox>
  )
}
