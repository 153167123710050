import { useStaticQuery, graphql } from "gatsby"

export const useSummaryImage = () => {
  const data = useStaticQuery(
    graphql`
      query SummaryImage {
        allImageSharp(
          filter: {
            original: { src: { regex: "/better-open-graph-summary-image/" } }
          }
        ) {
          edges {
            node {
              id
              fixed(width: 1200) {
                src
              }
            }
          }
        }
      }
    `
  )
  return data.allImageSharp.edges[0].node.fixed
}
