import * as React from "react"
import { FC, ReactNode } from "react"
import { Box } from "theme-ui"

export interface IPropsUnderline {
  className?: string
  children: ReactNode
}

export const Underline: FC<IPropsUnderline> = ({ children, className }) => {
  return (
    <Box
      as="span"
      className={className}
      sx={{
        textDecoration: "none",
        ["--thickness"]: "0.4em",
        display: "inline",
        position: "relative",
        backgroundImage:
          "linear-gradient(to bottom, currentColor 25%, transparent 25%)",
        backgroundSize: "0% var(--thickness)",
        backgroundRepeat: "no-repeat",
        backgroundPositionX: "right",
        backgroundPositionY: "bottom",
        transition: "background-size 0.3s",
        // -webkit-tap-highlight-color: rgba($bet-color-caribbean, 0.2),
        py: "var(--thickness)",

        "@media (hover: none)": {
          backgroundImage: "none",
        },

        'a[aria-current="page"] &, :hover': {
          backgroundSize: "100% var(--thickness)",
          backgroundPositionX: "left",
        },
      }}
    >
      {children}
    </Box>
  )
}
