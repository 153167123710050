import * as React from "react"
import { FC, ReactNode, createContext, useState, useRef } from "react"
import { useScrollPosition } from "@n8tb1t/use-scroll-position"

import { Navbar } from "components/Navbar"

export interface IPropsLayout {
  children: ReactNode
  className?: string
  navOnLoad?: "transparent" | "opaque"
  showLogo?: boolean
}

export const ScrollContext = createContext({
  scrolled: false,
  scrolledViewport: false,
})

export const Layout: FC<IPropsLayout> = ({
  children,
  className,
  navOnLoad,
  showLogo,
}) => {
  const [scrolled, setScrolled] = useState(false)
  const [scrolledViewport, setScrolledViewport] = useState(false)
  const elementRef = useRef<HTMLElement | null>()
  // Element scroll position
  useScrollPosition(
    ({ currPos }) => {
      setScrolled(currPos.y < window.innerHeight * -0.3)
      setScrolledViewport(currPos.y < window.innerHeight * -1)
    },
    [],
    elementRef
  )
  return (
    <ScrollContext.Provider value={{ scrolled, scrolledViewport }}>
      <Navbar showLogo={showLogo} navOnLoad={navOnLoad} />
      <main className={className} ref={elementRef}>
        {children}
      </main>
    </ScrollContext.Provider>
  )
}
