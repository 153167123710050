import * as React from "react"
import { FC } from "react"
import { GatsbyImage } from "gatsby-plugin-image";
import { Helmet } from "react-helmet"
import { useStaticQuery, graphql } from "gatsby"

import { useSummaryImage } from "../../hooks/use-summary-image"

interface IPropsSEO {
  author?: string
  date?: string
  description?: string
  excerpt?: string
  lang?: string
  location: string
  image?: {
    childImageSharp: GatsbyImage
  }
  meta?: Record<string, unknown>[]
  title: string
}

export const SEO: FC<IPropsSEO> = ({
  author,
  date,
  description,
  excerpt,
  image,
  lang = "en",
  location,
  meta = [],
  title,
}) => {
  const query = graphql`
    query {
      site {
        siteMetadata {
          title
          description
          author
          siteUrl
        }
      }
    }
  `

  const { site } = useStaticQuery(query)

  const { src: summaryImageSrc } = useSummaryImage()

  const {
    author: siteAuthor,
    title: siteTitle,
    siteUrl,
    description: siteDescription,
  } = site.siteMetadata
  const pageUrl = `${siteUrl}${location}`
  const pageTitle = title || siteTitle
  const metaDescription = description || excerpt || siteDescription
  const metaAuthor = author || siteAuthor
  const postImageSrc = image?.childImageSharp?.gatsbyImageData?.src
  const imageSrc = postImageSrc ? postImageSrc : summaryImageSrc
  const summaryImageType = postImageSrc ? "summary_large_image" : "summary"

  return (
    <>
      <Helmet
        htmlAttributes={{
          lang,
        }}
        title={pageTitle}
        titleTemplate={`%s | ${siteTitle}`}
        meta={[].concat(meta)}
      >
        <link rel="canonical" href={pageUrl} />
        <meta name="description" content={metaDescription} />
        <meta name="og:description" content={metaDescription} />
        <meta name="og:title" content={pageTitle} />
        <meta name="og:type" content="website" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:creator" content={metaAuthor} />
        <meta name="twitter:description" content={metaDescription} />
        <meta name="twitter:title" content={pageTitle} />
        <meta name="author" content={metaAuthor} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:image" content={imageSrc} />
        <meta property="og:locale" content="en_US" />
        <meta property="og:site_name" content={siteTitle} />
        <meta property="og:title" content={pageTitle} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={pageUrl} />
      </Helmet>
      {date && (
        <Helmet>
          <meta property="article:published_time" content={date} />
          <meta name="twitter:card" content={summaryImageType} />
          <meta name="twitter:creator" content={metaAuthor} />
          <meta name="twitter:image" content={imageSrc} />
          <script type="application/ld+json">{`
              {
                "@context":"http://schema.org",
                "@type":"BlogPosting",
                "headline": ${pageTitle},
                "author": {
                  "@type":"Person",
                  "name": ${metaAuthor}
                },
                "image": ${imageSrc},
                "datePublished": ${date},
                "dateModified": ${date},
                "description": ${metaDescription},
                "mainEntityOfPage": {
                  "@type":"WebPage",
                  "@id": ${pageUrl}
                },
                "url": ${pageUrl}
              }
          `}</script>
        </Helmet>
      )}
    </>
  )
}
