/** @jsx jsx */
import { FC, ReactNode } from "react"
import { Link } from "gatsby"

import { jsx, Text } from "theme-ui"
import { Underline } from "components/Underline"

export interface IPropsNavbarLink {
  children: ReactNode
  to: string
}

export const NavbarLink: FC<IPropsNavbarLink> = ({ to, children }) => {
  return (
    <li>
      <Link
        to={to}
        sx={{
          "span::selection": {
            bg: "backgroundSelectionDark",
          },
          color: "textWhite",
          variant: "layout.focusVisible",
          pointerEvents: "all",
          textDecoration: "none",
          display: "inline-grid",
          flexShrink: 0,
        }}
      >
        <Text
          sx={{
            color: "textWhite",
            fontSize: [2, 5, 4, null, 5],
            lineHeight: 1,
            whiteSpace: "nowrap",
          }}
        >
          <Underline
            sx={{
              ["--thickness"]: ["4px", "6px"],
              py: ["6px", 2],
              'a[aria-current="page"] &': {
                backgroundSize: "100% var(--thickness)",
                backgroundPositionX: "left",
              },
            }}
          >
            {children}
          </Underline>
        </Text>
      </Link>
    </li>
  )
}
